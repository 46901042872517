@mixin mod-hero-carousel() {
  /*Start Module: Hero*/
  .hero {
    margin-bottom: 50px;
    .custom-info-privacy {
      padding-left: 0 !important;
    }
    position: relative;

    .slide {
      .carousel-indicators {
        margin-bottom: 0.2rem;
        z-index: 10;
        li {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          opacity: 1;
          margin-left: 5px;
          margin-right: 5px;
          background-color: $light-grey-1;
          &.active {
            background-color: $white;
          }
        }
      }
      .carousel-inner {
        z-index: 2;
        .carousel-item {
          // max-height: 600px;
          // height: calc(100vh - 40px);
          overflow: hidden;
        }
      }
      [class|="carousel-control"] {
        @include rem-fallback(font-size, 40);
        width: 120px;
        z-index: 3;
      }
    }
  }

  /*End Module: Hero*/
}

@mixin mod-hero-carousel-tablet-landscape() {
  /*Start Module: Hero - Tablet Landscape*/
  .hero {
    .slide {
      [class|="carousel-control"] {
        width: 100px;
      }
    }
  }
  /*End Module: Hero - Tablet Landscape*/
}

@mixin mod-hero-carousel-tablet-portrait() {
  /*Start Module: Hero - Tablet Portrait*/
  .hero {
    .slide {
      .carousel-indicators {
        // display: none;
        margin-bottom: 2.2rem;
      }
    }
  }
  /*End Module: Hero - Tablet Portrait*/
}

@mixin mod-hero-carousel-mobile() {
  /*Start Module: Hero - Mobile*/
  .hero {
    .custom-control-info {
      a {
        color: white;
        text-decoration: underline;
      }
    }
    // margin-top: -55px;
    .slide {
      .carousel-indicators {
        margin-bottom: 2.2rem;
        & > li:first-child {
          margin-left: 0;
        }
      }
    }
  }
  /*End Module: Hero - Mobile*/
}

@mixin mod-hero-carousel-mobile-small() {
  .hero {
    // margin-top: -55px;
  }
}
