@mixin mod-carousel-media() {
    .carousel-media{

        .slider-card-small__card { //media-video
            margin-top: 40px;
            margin-bottom: 30px;
        }
        .swiper-slide{
            place-self: center;
            align-self: center;
        }
        .swiper--arrow--nav{
            width: 920px; //avvicino le arrows
        }
        height: auto;
        // height: 861px;
        padding-bottom: 30px;
        &.small-size{
            // height: 753px;
            .row-title{
                text-align: center;
            }
        }
        
        .swiper-pagination{
            // margin-top: 70px;
            padding-top: 50px;
            &-bullet-active{
                background: $white !important;
            }
        }
        .row-title{
            padding-top: 40px;
            .solo-title{
                font-size: 42px;
                font-weight: bold;
                line-height: 1.14;
            }
        }
        .text{
            text-align: center;
            bottom: 70px;
            // margin-top: 11px;
        }
        .card-image{
            img{
                width: 772px;
                height: auto;
                border-radius: 25.6px;
                object-fit: fill;
            }

        }
        .card-video{
            .video{
                width: 772px;
                height: 514px;
                .youtubePlayer{
                    border-radius: 32px;
                }
            }
        }
    }
}

@mixin mod-carousel-media-desktop() {
    .carousel-media{

        &.bg-arancio-chiaro {
            h2.title {
                margin-left: 110px;
            }

            h3.description {
                margin-right: 110px;
            }
        }
        .swiper--arrow--nav{
            width: 1100px; //avvicino le arrows
        }
        .card-image{
            img{
                width: 996px; 
                // height: 664px; 
                border-radius: 32px; 
                
            }
    
        }
        .card-video{
            .video{
                width: 996px; 
                height: 664px; 
                .youtubePlayer{
                    border-radius: 25.6px;
                }
            }
        }
    }
}

@mixin mod-carousel-media-tablet-portrait() {
    .carousel-media{
        .swiper--arrow--nav{
            width: 700px; //avvicino le arrows
        }
        .container{
            padding-right: 30px;
            padding-left: 30px;
        }
        .swiper--arrow--nav{
            display: block;
        }

        .swiper-pagination{
            padding-top: 30px;
        }

        .title, 
        .solo-title{
            font-size: 28px !important;
            line-height: 1.21 !important;
        }

        .description{
            font-size: 18px;
            line-height: 1.33;      
        }

        .text{
            bottom: 60px;
        }
        .card-image{
            img{
                width: 588px;
                // height: 392px;
                border-radius: 19.5px;
                
            }
    
        }
        .card-video{
            .video{
                width: 588px;
                height: 392px;
                .youtubePlayer{
                    border-radius: 19.5px;
                }
            }
        }
    }
}

@mixin mod-carousel-media-mobile() {
    .carousel-media{
        .row-title{
            text-align: center;
        }

        .title, 
        .solo-title{
            font-size: 28px !important;
            line-height: 1.21;
        }

        .description{
            font-size: 18px;
            line-height: 1.33;      
        }

        .text{
            bottom: 60px;
        }
        
        .swiper-pagination{
            padding-top: 20px;
        }
        .card-image{
            img{
                max-width: 344px;
                width: 100%;
                // height: 229px;
                border-radius: 11.4px;
            }
    
        }
        .card-video{
            .video{
                max-width: 344px;
                width: 100%;
                height: 229px;
                .youtubePlayer{
                    border-radius: 11.4px;
                }
            }
        }
    }
}
  