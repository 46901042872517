@mixin mod-vetrina-prodotti() {
    .vetrina-prodotti {
        overflow: hidden;

        .swiper--arrow--nav{
            z-index: 2 !important;
            top: 45% !important;
        }

        .slider-large-card {
            margin-top: 0;
            padding-top: 0;
            .title-carousel {
                display: none;
            }
        }

        .swiper-pagination{
            height: 30px;
        }

        .swiper-slide {
            margin-top: 15px;
        }

        .caption-title {
            // padding-top: 60px;
            color: $blue-medium;
            justify-content: center;
        }

        .title {
            padding-top: 10px;
            justify-content: center;
            .font-sans-display-1 {
                width: 700px;
                text-align: center;
            }
        }

        .chips-container {
            padding-top: 40px;
            width: 100%;
            justify-content: center;
            margin: 0 auto;
            padding-bottom: 25px;
            .alleanza-chip {
                width: 180px;
                height: 42px;
                margin-left: 20px;
                margin-right: 20px;
                border-radius: 21px;
                box-shadow: $shadow;
                span {
                   color: $theme-color--text;
                   font-family: 'AlleanzaSerif';
                   text-align: center;
                }
                &:focus:after {
                    border: none;
                }
            }
        }
        .carousels-card {
            width: 100%;
        }
    }
}

@mixin mod-vetrina-prodotti-desktop() {
    .vetrina-prodotti{
        
        .swiper--arrow--nav{
            max-width: 1050px;
        }
    }
}

@mixin mod-vetrina-prodotti-tablet-portrait() {
    .vetrina-prodotti {
        .caption-title {
            .font-caption-1 {
                font-size: 18px;
            }
        }

        .title {
            .font-sans-display-1 {
                width: 588px;
                line-height: 1.19;
                font-size: 32px;
            }
        }
        .chips-container {
            .alleanza-chip {
                width: 138px;
                height: 32px;
                margin-left: 12px;
                margin-right: 12px;
                border-radius: 21px;
                .font-serif-display-7 {
                    font-size:14px;
                } 
            }
        }
    }
}

@mixin mod-vetrina-prodotti-mobile() {
    .vetrina-prodotti {
        .caption-title {
            .font-caption-1 {
                font-size: 18px;
            }
        }

        .title {
            .font-sans-display-1 {
                width: 345px;
                line-height: 1.19;
                font-size: 32px;
            }
        }
        .chips-container {
            .alleanza-chip {
                width: 138px;
                height: 32px;
                margin-left: 15px;
                margin-right: 15px;
                margin-bottom: 10px;
                margin-top: 10px;
                .font-serif-display-7 {
                    font-size:14px;
                } 
            }
        }
        .slider-large-card {
            padding-top: 0;
        }
    }
}
