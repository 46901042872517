@import "../variablesFull";
@import "../../modules/carousel/mod-carousel-animated";
@import "../../modules/carousel/mod-carousel-media";
@import "../../modules/carousel/mod-carousel-quote";
@import "../../modules/carousel/mod-carousel-small";
@import "../../modules/carousel/mod-hero-carousel";
@import "../../modules/mod-vetrina-prodotti";

@include mod-carousel-small();
@include mod-carousel-animated();
@include mod-hero-carousel();
@include mod-carousel-media();
@include mod-carousel-quote();
@include mod-vetrina-prodotti();

/* Desktop viewport - More than 1440 */
@media all and (min-width: $viewport-width-desktop + px) {
    @include mod-carousel-small-desktop();
    @include mod-carousel-media-desktop();
    @include mod-carousel-quote-desktop();
    @include mod-vetrina-prodotti-desktop();
}

/* From Tablet Landscape to Tablet Portrait viewport - From 1440 to 990 */
@media all and (min-width: $viewport-width-tablet-portrait + px) and (max-width: ($viewport-width-tablet-landscape - 1) + px) {
    @include mod-carousel-small-tablet-landscape();
    @include mod-hero-carousel-tablet-landscape();
    @include mod-carousel-quote-tablet-landscape();
}

/* From Tablet Portrait to Mobile viewport - From 990 to 768 */
@media all and (min-width: $viewport-width-mobile + px) and (max-width: ($viewport-width-tablet-portrait - 1) + px) {
    @include mod-carousel-small-tablet-portrait();
    @include mod-hero-carousel-tablet-portrait();
    @include mod-carousel-media-tablet-portrait();
    @include mod-carousel-quote-tablet-portrait(); 
    @include mod-vetrina-prodotti-tablet-portrait();
}

/* Mobile viewport - Less then 768 */
@media all and (max-width: ($viewport-width-mobile - 1) + px) {
    @include mod-carousel-small-mobile();
    @include mod-hero-carousel-mobile();
    @include mod-carousel-media-mobile();
    @include mod-carousel-quote-mobile(); 
    @include mod-vetrina-prodotti-mobile();
}

/* Small Mobile viewport - Less then 375 */
@media all and (max-width: ($viewport-width-mobile-small - 1) + px) {
    @include mod-hero-carousel-mobile-small();
}