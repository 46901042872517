@mixin mod-carousel-small() {
  .slider-large-card{
    overflow-x: hidden;
    .swiper-slide{
      width: 772px !important;
    }
  }
  .slider-card-small, .slider-generic-small, .slider-large-card, .slider-medium-card {
    overflow-x: hidden;
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: $white;
    &.section-dark {
      background-color: $blue-medium;

      .title-carousel {
        color: $white;
      }
      .product-card:hover{
        box-shadow: $shadow-hover-dark;
      }
    }

    &.section-gray {
      background: $light-grey-1;
    }

    &.pg-section-four {
      & .section-title {
        @include rem-fallback(padding-bottom, 20);
      }
    }

    & .section-paragraph {
      @include rem-fallback(padding-bottom, 80);
      @include rem-fallback(font-size, 24);
      @include rem-fallback(line-height, 34);
      max-width: 990px;
      margin: auto;
      font-weight: 300;
      & b,
      & strong {
        font-weight: 500;
      }
    }

    .title-carousel {
      color: $color-alleanza-blue;
    }

    &__card {
      height: 100%;
      margin-top: 50px;
      margin-bottom: 50px;
      // padding-right: 20px;
      // padding-left: 20px;

      .img-card-prodotto {
        height: 100%;
        min-height: 180px;
        max-height: 180px;
        background-size: cover;
      }

      .card-body {
        padding: 20px 24px;
      }

      & .link {
        @include rem-fallback(margin-top, 20);
      }
    }

    & .swiper-slide > a {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
    }
  }

  .slider-generic-small {         
    .slider-card-small__card {
      margin-top: 40px !important;
      margin-bottom: 30px !important;
    }
  }   
}

@mixin mod-carousel-small-desktop() {
  .slider-large-card{
    .swiper-slide{
      width: 996px !important;
    }
  }
  .slider-card-small, .slider-generic-small, .slider-large-card, .slider-medium-card {
    &__card{
      padding-right: 0;
      padding-left: 0;
    }
  }

}
  
@mixin mod-carousel-small-tablet-landscape() {
  
  .slider-large-card {
    .small-container {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }

    .row {

      .swiper-container {
        // padding-left: 0px !important;
        // padding-right: 0px !important;
        padding-left: 30px !important;
        padding-right: 30px !important;
      }
    }

    // .swiper-button-next {
    //   margin-right: 9px !important;
    // }
  }

  .slider-medium-card, .slider-card-small, .slider-generic-small {

    .small-container {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }

    .row {
      // padding-left: 14px !important;
      // padding-right: 14px !important;

      .swiper-container {
        padding-left: 15px;
        padding-right: 16px;
        margin-left: 0px;
        margin-right: 0px;
      }
    }
  }

  .swiper-button-prev { 
    left: 5.1px !important;
  }
  .swiper-button-next {
    right: 5.1px !important;
  }
}

@mixin mod-carousel-small-tablet-portrait() {
  .slider-large-card{
    .swiper-slide{
      width: 588px !important;
    }
  }
  
  .swiper-container {
    max-width: unset;
    margin: 0 -15px;
  }
  .slider-medium-card {
    .row {
      // padding-left: 30px !important;
      // padding-right: 30px !important;

      .swiper-container {
        padding-left: 25px;
        padding-right: 25px;
          
        .swiper-slide{
          width: 348px !important;
        }
      }
    }
  }

  .slider-card-small{
    .swiper-slide{
      // width: 228px !important;
    }
  }

  .slider-card-small, .slider-generic-small {

    .row {
      // padding-left: 30px !important;
      // padding-right: 30px !important;

      .swiper-container {
        padding-left: 20px;
        padding-right: 21.5px;
        
        // .swiper-slide-next{
        //   opacity: 0.4 !important;
        // }
      }
    }

    .title-carousel{
      font-size: 32px;
    }

    &__card {
      
      .img-card-prodotto {
        height: 100%;
        min-height: 161px;
        max-height: 161px;
        background-size: cover;
      }

      .card-body {
        padding: 20px;
      }
    }

    & .section-paragraph {
      @include rem-fallback(padding-bottom, 45);
      @include rem-fallback(font-size, 20);
      line-height: normal;
    }
  }

  .swiper-button-prev {
    left: -15px !important;
  }

  .swiper-button-next {
    right: -15px !important;
  }

  .slider-generic-small {         
    .slider-card-small__card {
      margin-top: 20px !important;
      margin-bottom: 30px !important;
    }
  }   
}

@mixin mod-carousel-small-mobile() {
  .slider-large-card{
    .swiper-slide{
      // width: 345px !important;
      width: 300px !important;
    }
  }
  .slider-generic-small{
    .swiper-wrapper {
      width: 228px !important;
    }

  }
  .slider-card-small, .slider-generic-small, .slider-medium-card {
    .title-carousel {
      font-size: 32px;
    }

    
    .swiper-slide{
      width: 100% !important;
    }
    
    .swiper--arrow--nav {
      display: none;
    }
    .swiper-slide-next, .swiper-slide-prev{
      opacity: 0.4;

    }

    // .swiper-slide:last-child{
    //     opacity: 1;
    // }
    
    .swiper-wrapper {
      width: 282px;
    }

    &__card {
      margin-bottom: 30px;
      .img-card-prodotto {
        height: 100%;
        min-height: 160px;
        max-height: 160px;
        background-size: cover;
      }

      .card-body {
        padding: 20px 24px;
      }

      &__title {
        i {
          @include rem-fallback(font-size, 22, "phone-text");
        }
      }

      & .link {
        .megamenu-desktop--menu--section--footer--action-item {
          @include rem-fallback(font-size, 16, "phone-text");
        }
      }
    }
    
    & .section-paragraph {
      @include rem-fallback(padding-bottom, 35);
      @include rem-fallback(font-size, 18, "phone-text");
      line-height: normal;
      max-width: none;
    }
  }

  .slider-generic-small {         
    .slider-card-small__card {
      margin-top: 20px !important;
      margin-bottom: 30px !important;
    }
  }
}
