@mixin mod-carousel-quote() {
    .card-quote {
        text-align: center;
        margin-bottom: -25px;
        position: relative;
        float: left;
        left: 50%;
        transform: translate(-50%, 0%);

        .row {
            width: 588px; 
        }

        .virgolette {
            img {
                width: 94px;
                height: 70px;
                margin-bottom: 20px;
            }
        }
    
        .text {
            color: $color-alleanza-blue;
            .font-serif-display-3 {
                font-weight: 500;
            }
        }

        .agente{
            margin-top: 20px;
            // padding-left: 25px;
            &-photo{
                // padding-left: 10px;
                padding-right: 5px;
                text-align: right !important;
                // margin-left: 214px;
                &-img{
                    background-size: cover;
                    width: 40px; 
                    height: 40px;
                    border-radius: 24px;
                }
            }
            &-desc{
                max-width: 230px;
                padding-left: 5px;
                // padding-right: 85px;
                text-align: left !important;
                align-self: center;
                &-name{
                    font-family: 'AlleanzaLibre';
                    font-size: 14px;
                    line-height: 14px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1;
                    letter-spacing: normal;
                    color: $color-alleanza-blue;
                    text-align: left !important;
                    // margin-right: 213px;
                }
                &-role{
                    font-size: 12px;
                    line-height: 18px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    color: $dark-grey-2;
                    text-align: left !important;
                }
            }
        }
    }
    .slider-quote{
        overflow: hidden;
        .slider-quote__card{
            align-self: center;
        }
        .swiper-slide-next,.swiper-slide-prev{
            opacity: 0.4;
  
        }

        .swiper-pagination {
            margin-top: -10px;
        }
    }

    
}

@mixin mod-carousel-quote-desktop() {
    .card-quote {
        text-align: center;
        position: relative;
        float: left;
        left: 50%;
        transform: translate(-50%, 0%);
        margin-bottom: -25px !important;
        margin-left: -70px;
    }
}

@mixin mod-carousel-quote-tablet-landscape() {

    .card-quote {
        text-align: center;
        position: relative;
        float: left;
        left: 50%;
        transform: translate(-50%, 0%);
        margin-bottom: -25px !important;
        margin-left: -20px;

        .virgolette {
            img {
                width: 94px;
                height: 70px;
                margin-bottom: 20px;
            }
        }
    }

}

@mixin mod-carousel-quote-tablet-portrait() {
    .slider-quote{
        .swiper-wrapper {
            // width: 768px !important;
            margin-left: -70px;
            
            .swiper-slide {

                .card-quote{
                    text-align: center;
                    position: relative;
                    float: left;
                    left: 50%;
                    transform: translate(-50%, 0%);
                    width: 468px;
                    text-align: center;
                    margin-left: -20px;

                    .row{
                        width: 100%; 
                    }


                    .virgolette {
                        img {
                            width: 56px;
                            height: 42px;
                            margin-bottom: 10px;
                        }
                    }
                    .text{
                        .font-serif-display-3 {
                            font-weight: 500;
                            font-size: 22px !important;
                            line-height: 28px !important;
                        }
                    }
                    .agente{
                        margin-top: 23px;
                        &-desc{
                            padding-left: 5px;
                            text-align: left !important;
                            align-self: center;
                        }
                    }
                }
            }
        }    
    }   
}

@mixin mod-carousel-quote-mobile() {
    .slider-quote{
        .swiper-wrapper {
            // width: 375px !important;
            margin-left: -95px;
            
            .swiper-slide {
                .card-quote{
                    text-align: center;
                    position: relative;
                    float: left;
                    left: 50%;
                    transform: translate(-50%, 0%);
                    
                    .row{
                        width: 340px; 
                    }

                    .virgolette {
                        img {
                            width: 56px;
                            height: 42px;
                            margin-bottom: 10px;
                            margin-left: 25px;
                        }
                    }

                    .text{
                        width: 344px !important;
                        height: 140px !important;
                        .font-serif-display-3 {
                            font-size: 22px !important;
                            line-height: 28px !important;
                            width: 344px !important;
                            height: 140px !important;
                        }
                    }
                    .agente{
                        margin-top: 23px;
                        margin-left: 8px;
                        
                        &-desc{
                            padding-left: 5px;
                            text-align: left !important;
                            align-self: center;
                        }
                    }
                }
            }
            
        }

            
        .swiper-pagination {
            // width: 70px !important;
            justify-content: center;
            // margin: 0 auto !important;
            margin-left: 5x;
            margin-top: -10px !important;
            text-align: center;
        }
    }

}
  