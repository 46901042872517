@mixin mod-carousel-animated() {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .carousel-animated .swiper-container::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .carousel-animated .swiper-container {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .header.animated
  {
    overflow:hidden;
    .header--quick-nav{
      z-index:999;
      position: relative;
    }
    .header--content{
      z-index:-1;
      position: relative;
    }
  }
}
